



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '../../../../store';
import Error from '../../../../components/contents/Error.vue';
import VeeValidate from '../../../../plugins/vee-validate';
import { PasswordEdit } from '../../../../../types/typescript-axios/api';
import StepNavi from '../../../../components/contents/stepNavi/StepNavi.vue';

@Component({
  components: {
    Error,
    StepNavi,
  },
  mixins: [ VeeValidate ],
})
export default class LoginForgetPasswordEditPasswordConfirm extends Vue {
  private paramsName: any = '';
  private responseStatus: number = 200;
  private errorMessage: boolean = false;
  private responseStatusText: string = '';
  private newPasswordReInput: string = '';
  private get stepNavi(): string {
    const $parent: any = this.$parent;
    return $parent.stepNavi;
  }


  private get mail(): string {
    const $parent: any = this.$parent;
    return $parent.mail;
  }
  private get secretQuestAans(): string {
    const $parent: any = this.$parent;
    return $parent.secretQuestAans;
  }

  private get newPassword(): string {
    const $parent: any = this.$parent;
    return $parent.newPassword;
  }
  private set newPassword(value: string) {
    this.inputNewPassword(value);
  }

  get faqUrl(): string {
    return process.env.VUE_APP_PATH_FAQ;
  }

  @Emit()
  private inputNewPassword(value: string) {
    const $parent: any = this.$parent;
    $parent.setNewPassword(value);
  }

  private async onSubmit() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const key: any = this.$route.params.key;
        const params: PasswordEdit = {
          password: this.newPassword,
          mail_ad: this.mail,
          secret_quest_ans: this.secretQuestAans,
        };
        params.password = this.newPassword;
        try {
          await store.dispatch('account/resetPassword', {key, params});
        } catch (e) {
          this.$router.push('../error/');
          return;
        }
        this.$router.push('../complete/');
      } else {
        this.errorMessage = true;
        Vue.nextTick()
        .then(() => {
          const errorDome: any = this.$refs.errorMessage;
          window.scrollTo({
            top: window.pageYOffset + errorDome.getBoundingClientRect().top,
            behavior: 'smooth',
          });
        });
      }
    });
  }
  private mounted() {
    const params: any = this.$route.params;
    if (typeof params !== 'undefined') {
      this.paramsName = this.$route.params.courceId;
    }
  }
}
